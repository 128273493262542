import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './modules/auth'
import user from './modules/user'
import userPersonas from "./modules/userPersonas";
import client from './modules/client'
import site from './modules/site'
import customField from './modules/customField'
import zone from './modules/zone'
import assetType from './modules/assetType'
import imports from './modules/imports'
import assetGroup from './modules/assetGroup'
import asset from './modules/asset'
import sensor from './modules/sensor'
import riskLevel from './modules/riskLevel'
import barcode from './modules/barcode'
import checkPredefinedComments from './modules/checkPredefinedComments'
import checklist from './modules/checklist'
import check from './modules/check'
import jobType from './modules/jobType'
import job from './modules/job'
import role from './modules/role'
import inspection from './modules/inspection'
import dashboard from './modules/dashboard'
import company from './modules/company'
import list from './modules/list'
import schedule from './modules/schedule'
import support from './modules/support'
import recurring from './modules/recurring'
import subscription from './modules/subscription'
import documents from './modules/documents'
import accountEnquiry from './modules/accountEnquiry'
import ability from './modules/ability'
import global from './modules/global'
import namingConventions from "./modules/namingConventions"
import dynamicStatus from "./modules/dynamicStatus";
import assetRegister from "./modules/assetRegister"

export default new Vuex.Store({
  modules: {
    auth,
    user,
    userPersonas,
    client,
    site,
    customField,
    zone,
    asset,
    sensor,
    riskLevel,
    barcode,
    assetType,
    assetGroup,
    imports,
    checkPredefinedComments,
    checklist,
    check,
    jobType,
    job,
    role,
    inspection,
    dashboard,
    company,
    list,
    schedule,
    support,
    recurring,
    subscription,
    documents,
    accountEnquiry,
    ability,
    global,
    namingConventions,
    dynamicStatus,
    assetRegister,
  }
})
