import { company as backend } from '@/api'
import { CompanyBranding } from '@/models/CompanyBranding';
import Company from '@/models/Company'
import groupBy from 'lodash/groupBy'

export default {
  namespaced: true,

  state: {
    company: new Company,
    billingSummary: {

    },
    cancellation: {
      reason: ''
    },
    notification_channels: [],
    branding: new CompanyBranding,
  },

  mutations: {
    setCompany: (state, payload) => state.company = payload,
    setLogo: (state, payload) => state.company.logo_url = payload,
    name: (state, payload) => state.company.name = payload,
    vatNumber: (state, payload) => state.company.vat_number = payload,
    registrationNumber: (state, payload) => state.company.registration_number = payload,
    setAddress: (state, payload) => state.company.address = payload,
    updateIndustry: (state, payload) => state.company.industry_id = payload,
    setNotificationChannels: (state, payload) => state.notification_channels = payload,
    setWhiteLogoBackground: (state, payload) => state.company.white_logo_background = payload,
    setCompanyAssetRegisterConfiguration: (state, payload) => state.company.asset_register = payload,
    setAssetRegisterTitle: (state, payload) => state.company.asset_register.title = payload,
    setAssetRegisterPreamble: (state, payload) => state.company.asset_register.preamble = payload,
    setAssetRegisterPostamble: (state, payload) => state.company.asset_register.postamble = payload,

    // Billing
    setBillingSummary: (state, payload) => state.billingSummary = payload,
    setCancellation: (state, payload) => state.cancellation = payload || { reason: '' },
    clearCancellation: (state) => state.cancellation = {
      reason: ''
    },
    updateCancellationReason: (state, payload) => state.cancellation.reason = payload,
    setBillingEmail: (state, email) => state.company.subscription.billing_email = email,

    // Branding
    setBranding: (state, payload) => state.branding = new CompanyBranding(payload),
    clearBranding: (state) => state.branding = new CompanyBranding,
    setBrandingAppLogo: (state, payload) => state.branding.app_logo = payload,
    setCompanyAppLogoUrl: (state, payload) => state.company.branding.app_logo_url = payload,
    setCompanyAppLogo: (state, payload) => state.company.branding.app_logo = payload,
    setBrandingEmailLogo: (state, payload) => state.branding.email_logo = payload,
  },

  actions: {
    load: ({ commit }) => new Promise((resolve, reject) => {
      backend.load(({ data }) => {
        commit('setCompany', data)
        resolve()
      }, error => reject(error))
    }),
    update: ({ state }) => new Promise((resolve, reject) => {
      backend.update(state.company, ({ }) => {
        resolve()
      }, error => reject(error))
    }),
    uploadLogo: ({ commit }, file) => new Promise((resolve, reject) => {
      backend.uploadLogo(file, ({ data }) => {
        commit('setLogo', data)
        resolve()
      }, error => reject(error))
    }),
    resetCompanyLogo: ({ commit }) => new Promise((resolve, reject) => {
      backend.resetCompanyLogo(({ data }) => {
        commit('setLogo', data)
        resolve()
      }, error => reject(error))
    }),
    generateSubscriptionCheckout: ({ }) => new Promise((resolve, reject) => {
      backend.generateSubscriptionCheckout(response => {
        resolve(response)
      }, error => reject(error))
    }),
    submitCancellation: ({ commit }, payload) => new Promise((resolve, reject) => {
      backend.submitCancellation(payload, response => {
        commit('setCancellation', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadCancellation: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadCancellation(response => {
        commit('setCancellation', response.data)
        resolve()
      }, error => reject(error))
    }),
    stopCancellation: ({ commit }) => new Promise((resolve, reject) => {
      backend.stopCancellation(() => {
        commit('clearCancellation')
        resolve()
      }, error => reject(error))
    }),
    loadBillingSummary: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadBillingSummary(response => {
        commit('setBillingSummary', response.data)
        resolve()
      }, error => reject(error))
    }),
    loadSubscribableNotificationTypesByUser: ({ commit, state }, user) => new Promise((resolve, reject) => {
      backend.loadSubscribableNotificationTypesByUser(user, ({ data }) => {
        commit('setNotificationChannels', data)
        resolve()
      }, error => reject(error))
    }),
    loadSubscribableNotificationTypesByRole: ({ commit, state }, role) => new Promise((resolve, reject) => {
      backend.loadSubscribableNotificationTypesByRole(role, ({ data }) => {
        commit('setNotificationChannels', data)
        resolve()
      }, error => reject(error))
    }),
    loadCompanyBranding: ({ commit }) => new Promise((resolve, reject) => {
      backend.loadCompanyBranding(response => {
        commit('setBranding', response.data)
        resolve()
      }, reject)
    }),
    uploadBrandingAppLogo: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.uploadBrandingAppLogo(state.branding.appLogo, response => {
        commit('setBranding', response.data)
        commit('setCompanyAppLogoUrl', response.data.app_logo_url)
        commit('setCompanyAppLogo', response.data.app_logo)
        resolve()
      }, reject)
    }),
    resetBrandingAppLogo: ({ commit }) => new Promise((resolve, reject) => {
      backend.resetBrandingAppLogo(response => {
        commit('setBranding', response.data)
        commit('setCompanyAppLogoUrl', response.data.app_logo_url)
        commit('setCompanyAppLogo', response.data.app_logo)
        resolve()
      }, reject)
    }),
    uploadBrandingEmailLogo: ({ state, commit }) => new Promise((resolve, reject) => {
      backend.uploadBrandingEmailLogo(state.branding.emailLogo, response => {
        commit('setBranding', response.data)
        resolve()
      }, reject)
    }),
    resetBrandingEmailLogo: ({ commit }) => new Promise((resolve, reject) => {
      backend.resetBrandingEmailLogo(response => {
        commit('setBranding', response.data)
        resolve()
      }, reject)
    }),
    eftSubscriptionUpgrade: ({commit}) => new Promise((resolve, reject) => {
      backend.eftSubscriptionUpgrade(response => {
        resolve()
      }, reject)
    }),
    saveBillingEmail: ({ state }) => new Promise((resolve, reject) => {
      backend.saveBillingEmail(state.company.subscription.billing_email, resolve, reject)
    }),
    fetchCompanyAssetRegisterConfiguration: ({commit}) => new Promise((resolve, reject) => {
      backend.fetchCompanyAssetRegisterConfiguration(response => {
        commit('setCompanyAssetRegisterConfiguration', response.data)
        resolve()
      }, reject)
    }),
  },

  getters: {
    company: state => state.company,
    primaryUserId: state => state.company?.primary_user_id,
    billingSummary: state => state.billingSummary,
    subscription: state => state.company.subscription,
    isActive: state => state.company.subscription.active,
    cancellation: state => state.cancellation,
    notificationChannels: state => state.notification_channels,
    groupedNotificationChannels: state => {
      return groupBy(state.notification_channels, 'role.title')
    },
    getCompanyAssetRegisterConfiguration: state => state.company.asset_register,
  }
}
